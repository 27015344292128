import React from "react";
import "../../Elements/Navbar/Navbar.scss";

import { Button } from "@/components/Elements/Button";
import { Icon } from "@/components/Elements/Icon";

type CategoriesButtonProps = React.ButtonHTMLAttributes<HTMLDivElement> & {
	onToggleSubmenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
	isCategoriesOpen: boolean;
};

export const CategoriesButton = React.forwardRef<HTMLDivElement, CategoriesButtonProps>(
	({ onToggleSubmenu, children, isCategoriesOpen = false, ...props }, ref) => {
		return (
			<div ref={ref} {...props}>
				<Button
					id="categories-button"
					size="none"
					variant="none"
					className={`txt-btn-sm navbar__menu-link navbar__menu-button ${
						isCategoriesOpen ? "navbar__categories-btn-open" : ""
					}`}
					onClick={onToggleSubmenu}
					endIcon={
						<Icon
							id="arrow-categories"
							icon="arrow-icon"
							aria-hidden="true"
							className={`ml-2 navbar__categories-icon ${
								isCategoriesOpen ? "rotate-270" : "rotate-90"
							}`}
						/>
					}
				>
					Categories
				</Button>
				{children}
			</div>
		);
	}
);

CategoriesButton.displayName = "CategoriesButton";
