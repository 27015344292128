import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { StaticRoutes } from "@/routes/public";
import { sendAnalyticsPageview } from "@/utils/Analytics";

export const PageviewEvent = () => {
	const location = useLocation();

	useEffect(() => {
		if (Object.values(StaticRoutes).includes(location.pathname as StaticRoutes)) {
			sendAnalyticsPageview(location.pathname);
		}
	}, [location]);

	return null;
};
