import { Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Spinner } from "@/components/Elements/Spinner";
import { Layout } from "@/components/Layout";

const AboutUs = lazy(() => import("@/pages/AboutUs"));
const Category = lazy(() => import("@/pages/Category"));
const CategoryList = lazy(() => import("@/pages/CategoryList"));
const Confirm = lazy(() => import("@/pages/Confirm"));
// const DesignSystem = lazy(() => import("@/pages/DesignSystem")); // uncomment to see the design system in local
const Error = lazy(() => import("@/pages/Error"));
const GiftCenter = lazy(() => import("@/pages/GiftCenter"));
const FAQs = lazy(() => import("@/pages/FAQs"));
const Home = lazy(() => import("@/pages/Home"));
const Magazine = lazy(() => import("@/pages/Magazine"));
const Search = lazy(() => import("@/pages/Search"));
const ShippingPolicy = lazy(() => import("@/pages/ShippingPolicy"));
const Sitemap = lazy(() => import("@/pages/Sitemap"));
const ShopWithUs = lazy(() => import("@/pages/ShopWithUs"));

const App = () => {
	return (
		<Layout>
			<Suspense
				fallback={
					<div className="height-75">
						<Spinner />
					</div>
				}
			>
				<Outlet />
			</Suspense>
		</Layout>
	);
};

export enum StaticRoutes {
	HOME = "/",
	ABOUT = "/about",
	ALL_CATEGORIES = "/all-categories",
	CONFIRM = "/confirm",
	FAQ = "/faq",
	GIFT_CENTER = "/gift-center",
	SEARCH = "/search",
	SHIPPING_POLICY = "/shipping-policy",
	SITEMAP = "/sitemap",
	WHY_SHOP_WITH_US = "/why-shop-with-us",
}

enum DynamicRoutes {
	CATEGORY = "/category/:categoryName",
	MAGAZINE = "/:productId",
}

export const publicRoutes = [
	{
		path: StaticRoutes.HOME,
		element: <App />,
		children: [
			{ path: StaticRoutes.ABOUT, element: <AboutUs /> },
			{ path: StaticRoutes.ALL_CATEGORIES, element: <CategoryList /> },
			{ path: StaticRoutes.CONFIRM, element: <Confirm /> },
			{ path: StaticRoutes.FAQ, element: <FAQs /> },
			{ path: StaticRoutes.GIFT_CENTER, element: <GiftCenter /> },
			{ path: StaticRoutes.HOME, element: <Home /> },
			{ path: StaticRoutes.SEARCH, element: <Search /> },
			{ path: StaticRoutes.SHIPPING_POLICY, element: <ShippingPolicy /> },
			{ path: StaticRoutes.SITEMAP, element: <Sitemap /> },
			{ path: StaticRoutes.WHY_SHOP_WITH_US, element: <ShopWithUs /> },
			{ path: DynamicRoutes.CATEGORY, element: <Category /> },
			{ path: DynamicRoutes.MAGAZINE, element: <Magazine /> },
			{ path: "/error", element: <Error /> },
			{ path: "*", element: <Navigate to="." /> },
			// { path: "/ds", element: <DesignSystem /> }, // uncomment to see the design system in local
		],
	},
];
