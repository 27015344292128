export const categoriesSubList = [
	{
		id: 1,
		title: "$5 off titles",
		url: "/category/magazine-subscription-deals",
	},
	{
		id: 2,
		title: "all categories",
		url: "/all-categories",
	},
	{
		id: 3,
		title: "animals & pets",
		url: "/category/animals-and-pets",
	},
	{
		id: 4,
		title: "arts & crafts",
		url: "/category/arts-and-crafts",
	},
	{
		id: 5,
		title: "auto & cycles",
		url: "/category/auto-and-cycles",
	},
	{
		id: 6,
		title: "business & finance",
		url: "/category/business-and-finance",
	},
	{
		id: 7,
		title: "children",
		url: "/category/children-kids-magazine-subscriptions",
	},
	{
		id: 8,
		title: "computers & internet",
		url: "/category/computers-and-internet",
	},
	{
		id: 9,
		title: "digital magazines",
		url: "/category/digital-magazines",
	},
	{
		id: 10,
		title: "entertainment",
		url: "/category/entertainment",
	},
	{
		id: 11,
		title: "fashion & beauty",
		url: "/category/fashion-and-beauty",
	},
	{
		id: 12,
		title: "food & cooking",
		url: "/category/food-cooking-magazine-subscriptions",
	},
	{
		id: 13,
		title: "hobbies & collectibles",
		url: "/category/hobbies-and-collectibles",
	},
	{
		id: 14,
		title: "health & fitness",
		url: "/category/health-and-fitness",
	},
	{
		id: 15,
		title: "home & garden",
		url: "/category/home-and-garden",
	},
	{
		id: 16,
		title: "lifestyle",
		url: "/category/lifestyle",
	},
	{
		id: 17,
		title: "men's interest",
		url: "/category/mens-interest",
	},
	{
		id: 18,
		title: "news & politics",
		url: "/category/news-and-politics",
	},
	{
		id: 19,
		title: "family & parenting",
		url: "/category/parenting-family",
	},
	{
		id: 20,
		title: "religion",
		url: "/category/religion",
	},
	{
		id: 21,
		title: "science & nature",
		url: "/category/science-and-nature",
	},
	{
		id: 22,
		title: "spanish",
		url: "/category/spanish-hispanic-magazines",
	},
	{
		id: 23,
		title: "sports & recreation",
		url: "/category/sports-and-recreation",
	},
	{
		id: 24,
		title: "travel & regional",
		url: "/category/travel-and-regional",
	},
];
