import { combineReducers } from "@reduxjs/toolkit";

import { mdcApi } from "@/api/mdcAPI";

import counterReducer from "./counterSlice";

const rootReducer = combineReducers({
	counter: counterReducer,
	[mdcApi.reducerPath]: mdcApi.reducer,
});

export default rootReducer;
