import { Button } from "@/components/Elements/Button";
import { Icon } from "@/components/Elements/Icon";
import { SearchBar } from "@/components/Elements/SearchBar";
import "./SearchMobile.scss";

type SearchMobileProps = {
	onClickArrow: () => void;
	callbackOnCloseResults?: () => void;
};

export const SearchMobile = ({ onClickArrow, callbackOnCloseResults }: SearchMobileProps) => {
	return (
		<div className="search-mobile">
			<div className="search-mobile__container">
				<Button variant="icon" size="icon" onClick={onClickArrow}>
					<Icon
						icon="arrow-icon"
						className="svg-color-white rotate-180 search-mobile__icon"
						label="Open Navigation"
					/>
				</Button>
				<div className="search-mobile__search--mobile">
					<SearchBar callbackOnCloseResults={callbackOnCloseResults} focusOnLoad={true} />
				</div>
			</div>
		</div>
	);
};
