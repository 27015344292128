import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { PageviewEvent } from "@/components/Pageview";
import { ScrollToTop } from "@/components/ScrollToTop";
import { AppStore } from "@/state/configureStore";
import { GA4MeasurementID } from "@/utils/Analytics";

ReactGA.initialize("UA-43580378-2", { testMode: process.env.NODE_ENV === "test" });
ReactGA4.initialize(GA4MeasurementID(), { testMode: process.env.NODE_ENV === "test" });

ReactGA.plugin.require("ec");

const ErrorFallback = () => {
	useEffect(() => {
		window.location.href = "/error";
	}, []);
	return null;
};

type AppProviderProps = {
	children: React.ReactNode;
	store: AppStore;
};

export const AppProvider = ({ children, store }: AppProviderProps) => {
	return (
		<Suspense fallback={<div>fallback loading...</div>}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<HelmetProvider>
					<Provider store={store}>
						<Router>
							<ScrollToTop />
							<PageviewEvent />
							{children}
						</Router>
					</Provider>
				</HelmetProvider>
			</ErrorBoundary>
		</Suspense>
	);
};
