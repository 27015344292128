import { Link as RouterLink, LinkProps as LinkReactRouterProps } from "react-router-dom";
import "./Link.scss";

const variants = {
	buttonWhite: "bg-white text-primary bw-sm border-primary link__border",
	buttonPrimary: "bg-primary bg-hover-primary-dark text-white",
	buttonSecondary: "bg-white text-primary bw-xs border-solid border-primary",
	none: "",
};

const sizes = {
	xxs: "txt-xs",
	xs: "txt-btn-sm",
	sm: "txt-md",
	md: "txt-btn-md",
	custom: "",
};

const paddingSizes = {
	sm: "br-xs p-4 pt-3 pb-3",
	md: "br-xs p-4",
	none: "",
};

type IconProps =
	| { startIcon: React.ReactElement; endIcon?: never }
	| { endIcon: React.ReactElement; startIcon?: never }
	| { endIcon?: undefined; startIcon?: undefined };

export type LinkProps = React.ButtonHTMLAttributes<HTMLLinkElement> & {
	variant?: keyof typeof variants;
	colorIcon?: string;
	colorHoverIcon?: string;
	size?: keyof typeof sizes;
	padding?: keyof typeof paddingSizes;
} & IconProps &
	LinkReactRouterProps;

export const Link = ({
	className = "text-primary text-decoration-hover-primary",
	colorIcon = "svg-color-primary",
	colorHoverIcon = "svg-color-hover-primary",
	children,
	size = "md",
	variant = "none",
	startIcon,
	padding = "none",
	endIcon,
	...props
}: LinkProps) => {
	return (
		<RouterLink
			className={`link ${sizes[size]} ${variants[variant]} ${paddingSizes[padding]} ${className}`}
			{...props}
		>
			{startIcon || endIcon ? (
				<div className={`row items-center link__icon ${colorIcon} ${colorHoverIcon}`}>
					{startIcon}
					<span>{children}</span>
					{endIcon}
				</div>
			) : (
				<>{children}</>
			)}
		</RouterLink>
	);
};
