import { Head } from "@/components/Elements/Head";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";

import "./Layout.scss";

type LayoutProps = {
	children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
	return (
		<div className="app-container">
			<Head />
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
};
