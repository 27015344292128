import { Helmet } from "react-helmet-async";

type HeadProps = {
	title?: string;
	description?: string;
	keywords?: string;
	robots?: string;
};

const defaultTitle = "Magazine Discount Center";

export const Head = ({
	title = "",
	description = "",
	keywords = "",
	robots = "",
}: HeadProps = {}) => {
	return (
		<Helmet title={title ? `${title} | ${defaultTitle}` : undefined} defaultTitle={defaultTitle}>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			<meta name="robots" content={robots} />
		</Helmet>
	);
};
