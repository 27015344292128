import "./NavbarFooter.scss";

import { useLocation } from "react-router-dom";

import { Icon } from "@/components/Elements/Icon";
import { Link } from "@/components/Elements/Link";

export const NavbarFooter = () => {
	const currentLocation = useLocation();
	const handleOnTopClick = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		const mdcLogo = document.getElementById("mdc-logo-home");
		mdcLogo?.focus({ preventScroll: true });
	};
	return (
		<nav id="nav-footer" className="navbar-footer" aria-label="Navigation Menu Bottom">
			<ul className="navbar-footer__main-list">
				<li className="list-item">
					<Link to="/about" size="xs" className="navbar-footer__menu-link">
						About Us
					</Link>
				</li>
				<li className="list-item">
					<Link to="/faq" size="xs" className="navbar-footer__menu-link">
						FAQS
					</Link>
				</li>
				<li className="list-item">
					<Link to="/why-shop-with-us" size="xs" className="navbar-footer__menu-link">
						Why shop with us?
					</Link>
				</li>
				<li className="list-item">
					<Link to="/shipping-policy" size="xs" className="navbar-footer__menu-link">
						Shipping policies
					</Link>
				</li>
			</ul>
			<div>
				<Link
					to={currentLocation.pathname}
					size="xs"
					className="navbar-footer__menu-link uppercase"
					aria-label="Top of the page"
					onClick={handleOnTopClick}
					endIcon={
						<Icon
							icon="arrow-icon"
							aria-hidden="true"
							id="arrow-top"
							className="ml-2 navbar-footer__top-icon rotate-270"
						/>
					}
				>
					Top
				</Link>
			</div>
		</nav>
	);
};
