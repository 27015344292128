import { useEffect, useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useLazySearchMagazineQuery } from "@/api/mdcAPI";
import { CloseMatch } from "@/api/types";
import { Icon } from "@/components/Elements/Icon";
import { InputText } from "@/components/Elements/InputText";
import { Link } from "@/components/Elements/Link";
import { useClickOutsideMenu } from "@/hooks/useClickOutsideMenu";

import "./SearchBar.scss";

type Inputs = {
	searchParam: string;
};

type SearchBarProps = {
	callbackOnCloseResults?: () => void;
	focusOnLoad?: boolean;
};

const defaultValues = {
	searchParam: "",
};

export const SearchBar = ({ callbackOnCloseResults, focusOnLoad = false }: SearchBarProps) => {
	const navigate = useNavigate();
	const refInput = useRef<HTMLDivElement>(null);
	const refOptions = useRef<HTMLDivElement>(null);
	const [isTyping, setIsTyping] = useState(false);
	const [isListOpen, setIsListOpen] = useState(false);
	const [hasSearchStarted, setHasSearchStarted] = useState(false);
	const [results, setResults] = useState<CloseMatch[]>([]);
	const { handleSubmit, register, setFocus, setValue, watch } = useForm({ defaultValues });
	const watchUserInput = watch("searchParam");
	const [trigger, result] = useLazySearchMagazineQuery();
	const { data: searchData, isLoading, isFetching } = result;

	const handleCloseResults = () => {
		setIsListOpen(false);
		setValue("searchParam", "");
		if (callbackOnCloseResults) {
			callbackOnCloseResults();
		}
	};

	useClickOutsideMenu(refInput, refOptions, handleCloseResults);

	useEffect(() => {
		if (searchData?.results) {
			const mostPopularResults = searchData.results.most_popular.slice(0, 4) || [];
			const closeMatchResults = searchData.results.close_match.slice(0, 3) || [];
			const multipleResults = [...mostPopularResults, ...closeMatchResults];
			setResults(multipleResults);
		}
		if (searchData?.hasErrors) {
			setResults([]);
		}
	}, [searchData]);

	useEffect(() => {
		if (focusOnLoad) setFocus("searchParam");
	}, [setFocus, focusOnLoad]);

	useEffect(() => {
		if (watchUserInput) {
			setIsTyping(true);
			setIsListOpen(true);
		} else {
			setIsListOpen(false);
		}

		const timeOutId = setTimeout(() => {
			setHasSearchStarted(true);
			setIsTyping(false);
			if (watchUserInput) {
				trigger(watchUserInput);
			}
		}, 600);

		return () => clearTimeout(timeOutId);
	}, [watchUserInput, trigger]);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		navigate({
			pathname: "/search",
			search: `?param=${data.searchParam}`,
		});
	};

	const isDataLoading = (isTyping || isLoading || isFetching) && hasSearchStarted;

	return (
		<div className="search-bar">
			<div ref={refInput}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<InputText
						id="searchbar"
						label="Search Bar"
						placeholder="Search ..."
						endIcon={<Icon icon="search-icon" aria-hidden="true" />}
						autoComplete="off"
						hidden
						{...register("searchParam", { required: true })}
					/>
				</form>
			</div>
			{isListOpen && (
				<div ref={refOptions}>
					<ul className="search-bar__list">
						{isDataLoading && (
							<li className="search-bar__listitem search-bar__listitem--searching">Searching...</li>
						)}
						{!isDataLoading && (searchData?.hasErrors || results.length === 0) && (
							<li className="search-bar__listitem search-bar__listitem--searching">No results</li>
						)}
						{!isDataLoading &&
							results.map((item) => (
								<li key={item.productId} className="search-bar__listitem">
									<Link
										to={item.slug}
										size="custom"
										className="text-black width-full"
										onClick={handleCloseResults}
									>
										{item.title}
									</Link>
								</li>
							))}
					</ul>
				</div>
			)}
		</div>
	);
};
