import { useRef, useState } from "react";

import { Button } from "@/components/Elements/Button";
import { Categories } from "@/components/Elements/Categories";
import { Icon } from "@/components/Elements/Icon";
import { Link } from "@/components/Elements/Link";
import { Navbar } from "@/components/Elements/Navbar";
import { SearchBar } from "@/components/Elements/SearchBar";
import { SearchMobile } from "@/components/Elements/SearchMobile";
import { useClickOutsideMenu } from "@/hooks/useClickOutsideMenu";
import { useWindowDimensions } from "@/hooks/useWindowDimension";

import { CategoriesButton } from "./_CategoriesButton";

import "./Header.scss";

const minLargeResolution = 1200;

export const Header = () => {
	const { width } = useWindowDimensions();
	const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const refButton = useRef<HTMLDivElement>(null);
	const refNavbar = useRef<HTMLMenuElement>(null);
	const refCategories = useRef<HTMLDivElement>(null);

	const closeMobileMenu = () => setIsMobileMenuOpen(false);

	const handleToggleSubmenu = () => setIsCategoriesOpen(!isCategoriesOpen);

	const closeCategories = () => setIsCategoriesOpen(() => false);

	const handleOutsideClick = () => {
		setIsCategoriesOpen(false);
	};

	useClickOutsideMenu(refButton, refCategories, handleOutsideClick);
	useClickOutsideMenu(refNavbar, refButton, closeMobileMenu);

	return (
		<header className="header">
			<div className="container header__container">
				<div className="header__hamburger">
					<Button variant="icon" size="icon" onClick={() => setIsMobileMenuOpen(true)}>
						<Icon icon="hamburger-icon" label="Open Navigation" />
					</Button>
				</div>
				<Link to="/" size="xs" id="mdc-logo-home">
					<Icon icon="mdc-logo" className="header__logo" label="MDC Home Page" />
				</Link>
				<Navbar
					ref={refNavbar}
					onCloseMobileMenu={closeMobileMenu}
					isMobileMenuOpen={isMobileMenuOpen}
					categories={
						<CategoriesButton
							isCategoriesOpen={isCategoriesOpen}
							onToggleSubmenu={handleToggleSubmenu}
							ref={refButton}
						>
							{width < minLargeResolution ? (
								<Categories
									closeSubmenu={closeMobileMenu}
									className={isCategoriesOpen ? "categories--opened" : ""}
									ref={refCategories}
								/>
							) : null}
						</CategoriesButton>
					}
				/>
				<div className="header__search">
					{width >= minLargeResolution ? (
						<div className="header__search--desktop">
							<SearchBar />
						</div>
					) : (
						<Button variant="icon" size="icon" onClick={() => setIsSearchOpen(true)}>
							<Icon icon="search-icon" className="svg-color-white" label="Search Product" />
						</Button>
					)}
					{width < minLargeResolution && isSearchOpen && (
						<SearchMobile
							onClickArrow={() => setIsSearchOpen(false)}
							callbackOnCloseResults={() => setIsSearchOpen(false)}
						/>
					)}
				</div>
			</div>
			{width >= minLargeResolution ? (
				<Categories
					closeSubmenu={closeCategories}
					className={isCategoriesOpen ? "categories--opened" : ""}
					ref={refCategories}
				/>
			) : null}
		</header>
	);
};
