import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import { localEnvCheck, prodEnvCheck, stageEnvCheck } from "./utils";

interface EventProps {
	action: string;
	category: string;
	label: string;
}

const GA4_MID = {
	PRODUCTION: "G-261CVH4DF2",
	STAGING: "G-R28FPNR1ZP",
	DEV: "G-Y8E5MCNDCJ",
};

export const GA4MeasurementID = () => {
	if (localEnvCheck()) return GA4_MID.DEV;
	if (prodEnvCheck()) return GA4_MID.PRODUCTION;
	if (stageEnvCheck()) return GA4_MID.STAGING;
	return GA4_MID.DEV;
};

export const analyticsEvent = ({ category, action, label }: EventProps): void => {
	ReactGA.event({
		category,
		action,
		label,
	});

	ReactGA4.gtag("event", category, {
		category,
		action,
		label,
	});
};

export const sendAnalyticsPageview = (pathname: string) => {
	ReactGA.pageview(pathname);

	ReactGA4.send({
		hitType: "pageview",
		page: pathname,
	});
};

export const ga = (action: string, ...values: any) => {
	if (action === "set") {
		ReactGA4.gtag("set", "user_properties", {
			[values[0]]: values[1],
		});
	} else {
		if (!action.startsWith("ec") || !action.startsWith("send")) {
			ReactGA4.gtag(action, ...values);
		}
	}

	ReactGA.ga(action, ...values);
};

export const ga4EcommerceEvent = (action: string, ...values: any) => {
	ReactGA4.gtag("event", action, ...values);
};
