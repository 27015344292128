import { configureStore, PreloadedState } from "@reduxjs/toolkit";

import { mdcApi } from "@/api/mdcAPI";

import rootReducer from "./reducers/rootReducer";

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mdcApi.middleware),
	});
};

// Infer the `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
