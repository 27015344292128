import "./Spinner.scss";

type SpinnerProps = {
	text?: string;
};

export const Spinner = ({ text = "Please wait while we load your selection" }: SpinnerProps) => {
	return (
		<div className="spinner__container">
			<div className="spinner__loading"></div>
			<div className="mt-6">
				<p className="spinner__text">{text}</p>
			</div>
		</div>
	);
};
