export const removeNewLineSpecialCharacters = (text = "") => {
	if (text) {
		return text.replace(/\r?\n|\r|\\n|\\/g, "");
	}
	return text;
};

export const getEmailRegexMatches = (text: string): string[] => {
	const emailRegex = /\S+@\S+\.\S+/g;
	return text.match(emailRegex) || [];
};

export const getWordMatchesCount = (text: string, word: string): number => {
	const wordRegex = new RegExp(word, "gi");
	const matches = text.match(wordRegex);
	return matches ? matches.length : 0;
};

export const hyperlinkEmails = (text: string): string => {
	const emailMatches = getEmailRegexMatches(text);

	return emailMatches.reduce((processedText, email) => {
		const emailLink = `<a class="qa-tile__hyperlink" href="mailto:${email}">${email}</a>`;
		return processedText.replace(email, emailLink);
	}, text);
};

export const hyperlinkLastWord = (text: string, word: string, href: string): string => {
	const wordCount = getWordMatchesCount(text, word);

	if (wordCount === 0) {
		return text;
	}

	const wordRegex = new RegExp(word, "gi");
	let replacedCount = 0;

	return text.replace(wordRegex, (match) => {
		replacedCount++;
		return replacedCount === wordCount
			? `<a class="qa-tile__hyperlink" href="${href}">${match}</a>`
			: match;
	});
};

export const hyperlinkPhoneNumbers = (text: string): string => {
	const phoneRegex = /(\+?\d{1,2}[-.\s]?)?(\(?\d{1,3}\)?[-.\s]?)?(\d{1,4}[-.\s]?){2}\d{1,4}/g;

	return text.replace(phoneRegex, (match) => {
		const cleaned = match.replace(/[-.\s]/g, "");
		return `<a href="tel:${cleaned}">${match}</a>`;
	});
};

export const replaceSlashAndDashWithSpaces = (input: string): string => {
	const replaced = input.replace(/[/-]/g, " ");
	return replaced;
};

export const localEnvCheck = () => {
	return window.location.hostname.match(/synlocalhost|swatdev1/);
};

export const QAenvCheck = () => {
	return window.location.hostname.match(/qaswat|swatqa/);
};

export const stageEnvCheck = () => {
	const { hostname } = window.location;
	// Checks if the hostname has staging as subdomain
	return /^(staging\.)[^.]+\.[^.]+$/.test(hostname);
};

export const prodEnvCheck = () => {
	const { hostname } = window.location;
	// Checks if the hostname has www as subdomain or no subdomain at all
	return /^(www\.)?[^.]+\.[^.]+$/.test(hostname);
};
