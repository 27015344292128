import { useEffect } from "react";

type OutsideClickHandler = (event: MouseEvent | TouchEvent | KeyboardEvent) => void;

export const useClickOutsideMenu = (
	refButton: React.RefObject<HTMLElement>,
	refCategories: React.RefObject<HTMLElement>,
	handler: OutsideClickHandler
) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			if (
				refButton.current &&
				!refButton.current.contains(event.target as Node) &&
				refCategories.current &&
				!refCategories.current.contains(event.target as Node)
			) {
				handler(event);
			}
		};

		const handleEscapeKey = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				handler(event);
			}
		};

		const handleTouchOutside = (event: TouchEvent) => {
			if (
				refButton.current &&
				!refButton.current.contains(event.target as Node) &&
				refCategories.current &&
				!refCategories.current.contains(event.target as Node)
			) {
				handler(event);
			}
		};

		document.addEventListener("mouseup", handleClickOutside);
		document.addEventListener("touchstart", handleTouchOutside);
		document.addEventListener("keydown", handleEscapeKey);

		return () => {
			document.removeEventListener("mouseup", handleClickOutside);
			document.removeEventListener("touchstart", handleTouchOutside);
			document.removeEventListener("keydown", handleEscapeKey);
		};
	}, [refButton, refCategories, handler]);
};
