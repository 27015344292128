import "./Footer.scss";
import { Link } from "@/components/Elements/Link";
import { NavbarFooter } from "@/components/Elements/NavbarFooter";

export const Footer = () => {
	return (
		<footer className="footer">
			<div className="bg-primary">
				<div className="container footer__container-nav">
					<NavbarFooter />
				</div>
			</div>
			<div className="container footer__container-info">
				<div className="footer__certificates">
					<img
						width="119"
						height="45"
						alt="BBB Accredited business"
						src="./images/bbb_logo_horizontal.png"
						loading="lazy"
					/>
					<img
						width="97"
						height="45"
						alt="Secure Certified Site 128 BIT Encryption"
						src="https://static4.mybonuscenter.com/common/images/mdc/128bitencryption.png"
						loading="lazy"
					/>
				</div>
				<div className="footer__copy-links-container">
					<div className="footer__copy">
						<p>
							&copy; {new Date().getFullYear()} Magazine Discount Center.{" "}
							<span>All rights reserved.</span>
						</p>
					</div>
					<ul className="footer__links">
						<li>
							<Link to="https://privacy.synapsegroupinc.com" target="_blank" size="xxs">
								Privacy Policy
							</Link>
						</li>
						<li>
							<Link
								to="https://privacy.synapsegroupinc.com/img/privacy_terms_service_MDC.html"
								target="_blank"
								size="xxs"
							>
								Terms of Service
							</Link>
						</li>
						<li>
							<Link to="/sitemap" size="xxs">
								Sitemap
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
