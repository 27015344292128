import React from "react";
import "./Navbar.scss";

import { Button } from "@/components/Elements/Button";
import { Icon } from "@/components/Elements/Icon";
import { Link } from "@/components/Elements/Link";

type NavbarProps = React.MenuHTMLAttributes<HTMLMenuElement> & {
	onCloseMobileMenu: () => void;
	categories?: React.ReactElement | null;
	isMobileMenuOpen: boolean;
};

export const Navbar = React.forwardRef<HTMLMenuElement, NavbarProps>(
	({ onCloseMobileMenu, categories, isMobileMenuOpen }, ref) => {
		return (
			<nav
				ref={ref}
				id="nav"
				className={`navbar ${isMobileMenuOpen ? "open-nav" : ""}`}
				aria-label="main-navigation-menu"
			>
				<ul className="navbar__main-list">
					<li className="navbar__close-btn list-item">
						<Button onClick={onCloseMobileMenu} variant="icon" size="icon">
							<Icon icon="remove-icon" className="svg-color-grey" />
						</Button>
					</li>
					<li className="list-item">{categories}</li>
					<li className="list-item">
						<Link
							onClick={onCloseMobileMenu}
							to="/gift-center"
							size="xs"
							className="navbar__menu-link"
						>
							Gift Center
						</Link>
					</li>
					<li className="list-item">
						<Link
							onClick={onCloseMobileMenu}
							to="/category/digital-magazines"
							size="xs"
							className="navbar__menu-link"
						>
							Digital Magazines
						</Link>
					</li>
					<li className="list-item">
						<Link
							onClick={onCloseMobileMenu}
							to="https://www.magcustomerservice.com/wss/CommunitiesLogin?cid=mdc&redir=true"
							target="_blank"
							size="xs"
							className="navbar__menu-link"
						>
							Customer Support
						</Link>
					</li>
				</ul>
			</nav>
		);
	}
);

Navbar.displayName = "Navbar";
