import React from "react";
import { FieldError } from "react-hook-form";

import { Icon } from "@/components/Elements/Icon";

import "./InputText.scss";

const variants = {
	normal: "p-2 input__normal",
	rounded: "br-xs p-2 pl-4",
};

type InputTextProps = React.InputHTMLAttributes<HTMLInputElement> & {
	variant?: keyof typeof variants;
	endIcon?: React.ReactElement;
	label: string;
	id: string;
	errorMessage?: FieldError;
};

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
	(
		{
			id,
			label,
			hidden,
			variant = "normal",
			className = "",
			placeholder,
			endIcon,
			type = "text",
			errorMessage,
			...props
		},
		ref
	) => {
		const hasError = className.includes("input__error");
		const isRequired = props["aria-required"] === "true";

		return (
			<div className="input">
				<div className={!hidden ? "mb-2" : "mb-0"}>
					<label
						className={`${hasError ? "text-red" : "text-black"} ${
							isRequired ? "input__label--required" : "input__label"
						}`}
						htmlFor={id}
						hidden={hidden}
					>
						{label}
					</label>
				</div>
				<div className="input__text-container">
					<input
						ref={ref}
						id={id}
						aria-label={hidden ? label : undefined}
						type={type}
						className={`${variants[variant]}
						${
							endIcon
								? variant === "rounded"
									? "pr-8"
									: "pr-7"
								: variant === "rounded"
								? "pr-4"
								: ""
						} txt-md input__text ${className}`}
						placeholder={placeholder}
						{...props}
					/>
					{hasError && (
						<i
							className={`input__icon ${variant === "rounded" ? "pr-4" : "pr-2"}`}
							aria-hidden="true"
						>
							<Icon icon="remove-icon" aria-hidden="true" className="svg-color-red" />
						</i>
					)}
					{endIcon && !hasError && (
						<i
							className={`input__icon ${variant === "rounded" ? "pr-4" : "pr-2"}`}
							aria-hidden="true"
						>
							{endIcon}
						</i>
					)}
				</div>
				{hasError ? (
					<span role="alert" className="text-red">
						{errorMessage?.message}
					</span>
				) : null}
			</div>
		);
	}
);

InputText.displayName = "InputText";
