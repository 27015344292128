import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_URL, MDC_WEBPROMOTION } from "@/config/env";

import {
	AcceptOffer,
	AcceptOfferResponse,
	CategoryList,
	CategoryProducts,
	FeaturedCategories,
	Magazine,
	OrderInfo,
	Search,
	Session,
	Sitemap,
	User,
} from "./types";

// Define a service using a base URL and expected endpoints
export const mdcApi = createApi({
	reducerPath: "mdcApi",
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: ["OrderInfo", "OrderInfoConfirmation", "InitSession"],
	endpoints: (builder) => ({
		acceptOffer: builder.mutation<AcceptOfferResponse, AcceptOffer>({
			query: (orderPayload) => ({
				url: "/acceptOffer",
				method: "post",
				body: orderPayload,
			}),
		}),
		getCategoryList: builder.query<CategoryList, void>({
			query: () => ({ url: "/catalog/categorylist", method: "get" }),
		}),
		getCategoryProducts: builder.query<CategoryProducts, string>({
			query: (categoryName) => ({
				url: `/category/${categoryName}/products`,
				method: "get",
			}),
		}),
		getFeaturedCategories: builder.query<FeaturedCategories, void>({
			query: () => ({ url: "/catalog/featuredCategory", method: "get" }),
		}),
		getMagazine: builder.query<Magazine, string>({
			query: (slug) => ({
				url: `/product/${slug}/${MDC_WEBPROMOTION}/seq/1/page/Upfront`,
				method: "get",
			}),
		}),
		getGiftMagazine: builder.query<Magazine, string>({
			query: (slug) => ({
				url: `/product/${slug}/${MDC_WEBPROMOTION}/seq/2/page/Upfront`,
				method: "get",
			}),
		}),
		getOrderInfo: builder.query<OrderInfo, void>({
			query: () => ({
				url: `order/orderinfo?selections={"selections":["promotion", "upfrontItems"]}`,
				method: "get",
			}),
			providesTags: ["OrderInfo"],
		}),
		getOrderInfoConfirmation: builder.query<OrderInfo, void>({
			query: () => ({
				url: `order/orderinfo?selections={"selections":["promotion", "upfrontItems", "upsellItems", "userInfo"]}`,
				method: "get",
			}),
			providesTags: ["OrderInfoConfirmation"],
		}),
		getSitemap: builder.query<Sitemap, void>({
			query: () => ({
				url: `/sitemap`,
				method: "get",
			}),
		}),
		initSession: builder.query<Session, boolean>({
			query: () => ({
				url: "/session/init",
				method: "post",
				body: {
					initParams: {
						webPromotionId: MDC_WEBPROMOTION,
					},
				},
			}),
			providesTags: ["InitSession"],
		}),
		processEvent: builder.mutation<Session, void>({
			query: () => ({
				url: "/event/processevent",
				method: "post",
				body: {
					requestParams: {
						eventId: 1,
					},
				},
			}),
		}),
		searchMagazine: builder.query<Search, string>({
			query: (term) => ({
				url: `/search/${MDC_WEBPROMOTION}/1/Upfront`,
				params: { searchTerm: term },
				method: "get",
			}),
		}),
		getUsers: builder.query<User[], void>({ query: () => ({ url: "/users", method: "get" }) }),
	}),
});

export const {
	useAcceptOfferMutation,
	useGetCategoryListQuery,
	useGetCategoryProductsQuery,
	useGetFeaturedCategoriesQuery,
	useGetMagazineQuery,
	useGetGiftMagazineQuery,
	useGetOrderInfoQuery,
	useGetOrderInfoConfirmationQuery,
	useGetSitemapQuery,
	useInitSessionQuery,
	useProcessEventMutation,
	useSearchMagazineQuery,
	useLazySearchMagazineQuery,
	useGetUsersQuery,
} = mdcApi;
