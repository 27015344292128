import "./Icon.scss";

type IconProps = React.SVGAttributes<HTMLOrSVGElement> & {
	icon: string;
	className?: string;
	label?: string;
};

export const Icon = ({ icon, label, className = "", ...props }: IconProps) => {
	return (
		<svg className={`icon icon__${icon} ${className}`} {...props}>
			{label && <title>{label}</title>}
			<use href={`./images/sprite.svg#${icon}`} />
		</svg>
	);
};
