import React from "react";

import { Link } from "@/components/Elements/Link";

import { categoriesSubList } from "./items";

import "./Categories.scss";

type CategoriesProps = React.HTMLAttributes<HTMLDivElement> & {
	closeSubmenu: () => void;
	className?: string;
};

export const Categories = React.forwardRef<HTMLDivElement, CategoriesProps>(
	({ className = "", closeSubmenu, ...props }, ref) => {
		return (
			<div
				ref={ref}
				id="subnav"
				data-testid="subnav-element"
				className={`categories ${className}`}
				{...props}
			>
				<ul className="categories__sub-list">
					{categoriesSubList.map((category) => {
						return (
							<li key={category.id} className="list-subitem">
								<Link
									to={category.url}
									size="sm"
									className="categories__menu-sublink"
									onClick={closeSubmenu}
								>
									{category.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
);

Categories.displayName = "Categories";
