import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		const header = document.querySelector("header");
		if (header) {
			const firstFocusableElement = header.querySelector(
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
			) as HTMLElement;
			if (firstFocusableElement) {
				firstFocusableElement.focus({ preventScroll: true });
			}
		}
	}, [pathname]);

	return null;
};
