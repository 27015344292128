import React from "react";
import "./Button.scss";

const variants = {
	primary: "bg-primary bg-hover-primary-dark text-white",
	secondary:
		"bg-white text-primary text-hover-primary-dark bw-sm border-solid border-primary button__secondary",
	icon: "bg-transparent",
	none: "",
};

const sizes = {
	xs: "txt-btn-sm br-xs p-4 pt-2 pb-2",
	sm: "txt-btn-sm br-xs p-4 pt-3 pb-3",
	md: "txt-btn-md br-xs p-4",
	lg: "txt-btn-lg br-lg p-8 pt-4 pb-4",
	icon: "p-0",
	none: "",
};

type IconProps =
	| { startIcon: React.ReactElement; endIcon?: never }
	| { endIcon: React.ReactElement; startIcon?: never }
	| { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: keyof typeof variants;
	size?: keyof typeof sizes;
	isLoading?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			type = "button",
			role = "button",
			className = "",
			variant = "primary",
			size = "md",
			isLoading = false,
			startIcon,
			endIcon,
			...props
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				type={type}
				role={role}
				className={`${variants[variant]} ${sizes[size]} ${className}`}
				{...props}
			>
				<div className="row items-center justify-center button__icon">
					{!isLoading && startIcon}
					<span>{isLoading ? "Loading..." : props.children}</span> {!isLoading && endIcon}
				</div>
			</button>
		);
	}
);

Button.displayName = "Button";
